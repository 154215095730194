import logo from './logo.svg';
import './App.css';
import school from "./school_of_nifty.png"
import man from "./man.png"
import tick from "./righttik.png"
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.id === "subscribeButton") {
        fetch('https://api64.ipify.org?format=json')
          .then(response => response.json())
          .then(data => {
            const ipAddress = data.ip;
            // Fetch IP address successful, now send Facebook event
            fetch("https://graph.facebook.com/v19.0/1163525137978061/events", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                data: [
                  {
                    action_source: "website",
                    event_id: 9876512,
                    event_name: "Subscribe",
                    event_time: Math.floor(Date.now() / 1000), // Current Unix timestamp
                    user_data: {
                      client_ip_address: ipAddress,
                      client_user_agent: navigator.userAgent,
                      em: "f660ab912ec121d1b1e928a0bb4bc61b15f5ad44d5efdc4e1c92a25e99b8e44a"
                    }
                  }
                ],
                
                access_token: "EAAdM5RCwunwBOyGlNhhZAXOREjla2mRIOu8SiQOZBmKEcixgKIL1590QP3YkO9T9D5mOV8Qt2IQZA1MkagL0ZCAbD1lJfUV28ZBXfee7VgN3EvCfE9UswtfsXHOU5rlICQjSiCOSsxrO8NispXpEKEnt0D3gkH9LcS4nw8UCyP5GkxG0DKq4ZB6JZAeqkZCsjlOPpwZDZD"
              })
            })
              .then(response => {
                if (response.ok) {
                  console.log('Event tracked successfully');
                } else {
                  console.error('Failed to track event');
                }
              })
              .catch(error => {
                console.error('Error:', error);
              });
          })
          .catch(error => {
            console.error('Error fetching IP address:', error);
          });
      }
    };
  
    document.addEventListener('click', handleClick);
  
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);  

  return (
    <div className="App">
      <div className="blue-header">
        don't have <strong>Telegram</strong> yet? Try it now!
      </div>
      <div className="team-logo-wrapper">
        <div className="team-logo bg-team">
        </div>
        <div className="text-wrapper">
          <h1 className="avitr-t-name avitor-icon-link">BANK NIFTY 50 LEARNING</h1>
          <p className="gray-text">40,364 subscribers</p>
          <p className="info-text"> <img src={man} className="user-icon" alt="" /> Option Buying In Nifty And Banknifty <br /><br />START YOUR PROFITABLE TRADING JOURNEY FROM TODAY</p>
          <p className="Link-channer "> <img src={tick} className="user-icon" alt="" /> India's best channel for Option Buying In Nifty And Banknifty</p>
          <a id="subscribeButton" href="https://telegram.me/+gee6UXSUkWgxYmM1" className="view-btn" target="_blank" rel="noopener noreferrer" > View in telegram</a>
          <p className="info-link-text">if you have <strong>Telegram</strong> you can view and join <p id="subscribeButton" className="avitor-link avitor-icon-link" target="_blank" rel="noopener noreferrer"> <img src={school} className="user-icon" alt="" /> <strong>BANK NIFTY 50 LEARNING</strong></p> right away.</p>
        </div>
      </div>
    </div>

  );
}

export default App;
